import moment from "moment"
import { capitalize } from '@core/utils/filter'
import { formatMoney } from "@/helper/function"
import router from "@/router"


export default [
  
  {
    key: 'id',
    label: 'IB ID',
    settingLabel: 'IB ID',
    thClass: 'py-75 pl-2',
    tdClass: 'pl-2 py-75',
    thStyle: 'width: 5vw; max-width: 5vw'
  },
  {
    key: 'wallet_name',
    label: 'Wallet',
    settingLabel: 'Wallet',
    onclick: (item) => {
      router.push({
        name: 'client_details',
        params: {client_id: item.user.client.id, tab_key: 'wallets'},
        query: {id: item.wallet.id}
      })
    }
  },
  {
    key: 'user_name',
    label: 'Name',
    settingLabel: 'Name',
    onclick: (item) => {
      if (item.user?.client) {
        router.push({name: 'client_details', params: {client_id: item.user.client.id, tab_key: 'summary'}})
      }
    }
  },
  {
    key: 'user_email',
    label: 'Email',
    settingLabel: 'Email',
  },
  {
    key: 'commission_profile_name',
    label: 'IB Profile',
    settingLabel: 'IB Profile',
  },
  {
    key: 'level',
    label: 'IB Level',
    settingLabel: 'IB Level',
    filter: {
      filterType: "number"
    },
  },
  {
    key: 'master_ib_id',
    label: 'MIB ID',
    settingLabel: 'MIB ID',
  },
  {
    key: 'ib_type',
    label: 'Type',
    settingLabel: 'Type',
  },
  {
    key: 'vol_this_month',
    label: 'Vol This Month',
    settingLabel: 'Vol This Month',
    thClass: 'text-right ',
    tdClass: 'text-right',
    formatter: (value, index, item) => {
      return formatMoney(value)
    },
    filter: {
      type: "none"
    }
  },
  {
    key: 'commission_this_month',
    label: 'Commission This Month',
    settingLabel: 'Commission This Month',
    thClass: 'text-right ',
    tdClass: 'text-right',
    formatter: (value, index, item) => {
      return formatMoney(value)
    },
    filter: {
      type: "none"
    }
  },
  {
    key: 'total_com',
    label: 'Total Com',
    settingLabel: 'Total Com',
    thClass: 'text-right',
    tdClass: 'text-right',
    formatter: (value, index, item) => {
      return formatMoney(value)
    },
    filter: {
      type: "none"
    }
  },
  {
    key: 'state',
    label: 'State',
    settingLabel: 'State',
    thClass: 'text-right',
    tdClass: 'text-right',
    formatter: (value, index, item) => {
      return `<div class="${value == 'enabled' ? 'text-success' : 'text-warning'}">${capitalize(value)}</div>`
    },
    filter: {
      type: 'select',
      options: [
        {value: "All", text: "All"},
        {value: "enabled", text: "Enabled"},
        {value: "disabled", text: "Disabled"},
      ]
    }
  },
  {
    key: 'created_at',
    label: 'Registration Date',
    settingLabel: 'Registration Date',
    thClass: 'text-right pr-2',
    tdClass: 'text-right pr-2',
    filter: {
      type: "date",
      default: {
        filter: "",
        type: 'inRange',
        filterType: 'date'
      }
    },
    formatter: (value, key, item) => {
      return moment(value).format("DD/MM/Y")
    },
  },
  {
    key: 'filter',
    label: '',
    thClass: 'text-center',
    filter: {
      type: "none"
    }
  },
]