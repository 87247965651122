<template>
<!-- TODO: Filter partners on server -->
  <b-card class="h-100 my-border mb-0">
    <div class="d-flex justify-content-end" style="padding: 12px 16px">
      

      <div class="d-flex align-items-center">
        <font-awesome-icon class="font-medium-1 btn-icon" :icon="['fal', 'cog']"  @click="() => {$bvModal.show('table-setting-modal')}"/>
        <table-setting-modal
          :fields="fields"
          :excludedFields="excludedFields"
          @setExcludedFields="setExcludedFields"
        />
      </div>
    </div>

    <div class="custom-table-height">
      <common-table
        :items="items"
        :fields="includedFields"
        :perPage="perPage"
        :currentPage="currentPage"
        :selected="partners_selected"
        :showLoading="loading"
        @fetchItems="fetchItems"
        @setCurrentPage="(page) => currentPage = page"
        @selectedChange="(newSelected)  => partners_selected = newSelected"
      >
      </common-table>
    </div>
    <div class="d-flex justify-content-between py-50 border-top font-small-3">
      <div class="my-auto ml-2">
        Show
        <b-dropdown :text="String(perPage)" variant="outline-secondary" toggle-class="custom-dropdown" class="dropdown-borderless">
          <b-dropdown-item v-for="item in [10, 20, 50, 100]" :key="item" @click="perPage = item"> {{item}} </b-dropdown-item>
        </b-dropdown>
      </div>

      <div class="d-flex">
        <div class="my-auto mr-2">Showing {{Math.min(totalRows, (currentPage - 1) * perPage + 1)}} to {{Math.min(currentPage * perPage, totalRows)}} of {{totalRows}} entries</div>
        <b-pagination
          class="my-auto"
          size="sm"
          v-model="currentPage"
          :per-page="perPage"
          :total-rows="totalRows"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <font-awesome-icon :icon="['far', 'chevron-left']"/>
          </template>
          <template #next-text>
            <font-awesome-icon :icon="['far', 'chevron-right']"/>
          </template>
        </b-pagination>
      </div>
      <div style="width: 200px"/>
    </div>
    
  </b-card>
</template>

<script>
import store from "@/store"
import axios from "@axios"
import CommonTable from "@/components/table/CommonTable"
import moment from "moment"
import { capitalize } from '@core/utils/filter'
import { formatMoney } from "@/helper/function"
import { mapActions, mapGetters } from 'vuex'
import tableFields from "./tableFields"
import TableSettingModal from "@core/components/modals/TableSettingModal"


export default {
  components: {
    CommonTable, TableSettingModal
  },
  
  data() {
    return {
      user:  JSON.parse(localStorage.getItem('userData')),
      showSidebar: false,
      loading: false,
      partners_selected: [],
      ib: null,

      items: [],
      tableFields,
      excludedFields: [],
      perPage: 20,
      currentPage: 1,
      sort_by: null, 
      filter_by: {}
    } 
  },

  computed: {
    ...mapGetters({
      partners: "partner/formattedPartners",
      currentIbId: 'partner/currentIbId'
    }),
    totalRows() {
      return this.partners?.length
    },
    fields() {
      return this.tableFields?.filter(f => f.settingLabel)
    },
    includedFields() {
      return this.tableFields?.filter(f => !this.excludedFields.includes(f.key))
    }
  },

  methods: {
    moment, capitalize, formatMoney,

    ...mapActions({
      fetchPartnersStore: "partner/fetchPartners"
    }),

    fetchPartners() {
      this.loading = true
      this.fetchPartnersStore().then((res) => {
        this.fetchItems(this.perPage, this.currentPage, this.sort_by, this.filter_by)
      }).catch(err => {
        console.error(err)
      }).finally(() => {
        this.loading = false
      })
    },

    fetchItems(size, page, sort_by, filter_by) {
      console.log(size, page, sort_by, filter_by)
      this.sort_by = sort_by
      this.filter_by = filter_by
      this.items = this.partners;
      if (filter_by) {
        Object.entries?.(filter_by)?.forEach(([key, val]) => {
          if (val.filterType == 'date') {
            this.items = this.items.filter(x => moment(x.created_at).isAfter(val.dateFrom) && moment(x.created_at).isBefore(val.dateTo))
          } 
          else this.items = this.items.filter(x => x[key].toString().includes(val.filter.toString()))
        })
      }
      if (sort_by) {
        this.items = this.items.sort((a, b) => {
          let x = a[sort_by.key]
          let y = b[sort_by.key]
          let res = 0
          if (x < y) res = -1
          else if (x > y) res = 1 
          if (sort_by.direction == 'DESC') return -res
          return res
        })
      }
      const start = Math.min((page - 1) * size, this.totalRows);
      const end = Math.min(page * size, this.totalRows);
      this.items = this.items.slice(start, end)
      console.log(this.items)
    },

  
    setExcludedFields(fields) {
      localStorage.setItem("table_excluded_fields_partners", JSON.stringify(fields))
      this.$router.go(0)
    },
  },
  
  mounted(){
    let fromLocalStorage = localStorage.getItem("table_excluded_fields_partners");
    this.excludedFields = JSON.parse(fromLocalStorage) || []
    this.fetchPartners()
  },
}
</script>
<style scoped>
</style>