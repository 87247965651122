<template>
  <div class="sidebar-left" :class="{'width-60px': $store.state.app.rightSidebarWidth}">
    <div class="sidebar">
      <div class="sidebar-content email-app-sidebar"
        :style="{ width: '100%'}"
        :class="{ 'w-100':$store.state.app.rightSidebarWidth }"
      >
        <div class="email-app-menu">
          <!-- Filters -->
          <b-list-group class="list-group-messages">
            <b-list-group-item
              v-for="filter in tabs"
              :key="filter.title + $route.path"
              :to="filter.route"
              :active="isDynamicRouteActive(filter.route)"
            >
            <font-awesome-icon :icon="['far', filter.icon]" class="mr-75" />
              <span v-if="!$store.state.app.rightSidebarWidth">
                <span class="align-text-bottom line-height-1">{{filter.title}}</span>
                <span class="text-warning align-text-bottom line-height-1" v-if="numbers && numbers[filter.key]"> ({{numbers[filter.key]}})</span>
              </span>
            </b-list-group-item>
          </b-list-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BButton, BListGroup, BListGroupItem, BBadge } from "bootstrap-vue";
import { isDynamicRouteActive } from "@core/utils/utils";
import Ripple from "vue-ripple-directive";

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BButton,
    BListGroup,
    BListGroupItem,
    BBadge,
  },
  props: ['tab_filters', 'numbers'],
  setup(props) {
    const tabs = props.tab_filters

    return {
      // UI
      isDynamicRouteActive,

      // Filter & Labels
      tabs,
    };
  },
};
</script>

<style>
</style>
