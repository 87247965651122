<template>
  <div>
    <!-- LeftSideBar -->
    <portal to="content-renderer-sidebar-left">
      <left-sidebar :tab_filters="tab_filters"/>
    </portal>

    <!-- Table -->
    <div v-if="current_components">
      <component :is="current_components" />
    </div>

  </div>
</template>
<script>

import axios from '@axios'
import store from '@/store'
import { capitalize } from '@core/utils/filter'

import LeftSidebar from '../LeftSidebar'

// setup
import { useRouter } from '@core/utils/utils'
import { computed } from '@vue/composition-api'

import PartnerTable from './partner-list/PartnerTable'
import TreeList from './tree-list/TreeList'

export default {
  components: {
    LeftSidebar, PartnerTable, TreeList
  },


  data(){
    return {
      store,
      mapParamsToComponents: {
        "partner": "PartnerTable",
        "tree-list": 'TreeList',
      },
      tab_filters: [
        { title: "Partner List", icon: "list-ul", route: { name: "partners", params: { tab: "partner" } } },
        { title: "Tree List", icon: "chart-network", route: { name: "partner-tabs", params: { tab: "tree-list" } } },
      ],
      current_components: null,
    }
  },

  setup() {
    const { route } = useRouter()
    const routeParams = computed(() => route.value.params)
    return {
      routeParams,
    }
  },

  methods: {
    capitalize,

  },

  watch: {
    routeParams(value){
      store.commit('app/SET_SIDEBAR_WIDTH', 0)
      this.current_components = this.mapParamsToComponents[value.tab]
    }
  },

  mounted() {
    this.current_components = this.$route.params.tab ? this.mapParamsToComponents[this.$route.params.tab] : 'PartnerTable';
    store.commit('app/SET_SIDEBAR_WIDTH', 0)
  },
}

</script>
<style lang="scss">
@import "~@core/scss/base/pages/app-email.scss";
</style>
