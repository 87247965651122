<template>
  <div class="tree-list-tab">
    <b-card class="my-border">
      <div class="d-flex w-100 justify-content-between p-1">
        <div class="d-flex w-50">
          <font-awesome-icon
            :icon="['far', 'search']"
            class="my-auto mx-1 font-medium-3"
            @click="search"
          />
          <b-form-input
            class="tree-search-input w-50"
            size="sm"
            type="text"
            v-model="searchword"
            placeholder="Search IB"
            @keyup="enterSearch"
          />
        </div>

        <b-dropdown
          :text="level_filter"
          variant="flat-dark"
          size="sm"
          class="border"
          right
        >
          <b-dropdown-item @click="level_filter = 'All'"> All </b-dropdown-item>
          <b-dropdown-item @click="level_filter = 'Single Level'">
            Single Level
          </b-dropdown-item>
          <b-dropdown-item @click="level_filter = 'Multi Level'">
            Multi Level
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </b-card>
    <b-card>
      <v-tree
        ref="tree"
        :data="partnersTree"
        :halfcheck="true"
        :multiple="true"
        :tpl="tpl"
        searchable
        class="py-1"
      />
    </b-card>
  </div>
</template>

<script>
import { VTree, VSelectTree } from "vue-tree-halower";
import "vue-tree-halower/dist/halower-tree.min.css";
import axios from "@axios";
export default {
  components: {
    VTree,
    VSelectTree,
  },
  data() {
    return {
      user: JSON.parse(localStorage.getItem("userData")),
      partnersTree: [],
      parent: null,
      level_filter: "All",
      searchword: "",
    };
  },

  methods: {
    get_tree_data(data) {
      // leaf
      if (!data.children)
        return {
          ...data,
          checked: true,
          title: `${data.id} | ${data.user.full_name}`,
        };
      let result = {
        ...data,
        title: `${data.id} | ${data.user.full_name}`,
        expanded: true,
      };
      result.children = data.children.map((ib) => this.get_tree_data(ib));
      return result;
    },
    getPartnersTree() {
      let loading = this.$loading.show();
      axios
        .get(
          `${localStorage.getItem("VUE_APP_API_URL")}/partners?tree=1&user_id=${this.user.id}`
        )
        .then((res) => {
          this.partnersTree = res.data.map((item) => this.get_tree_data(item));
          console.log(this.partnersTree)
        })
        .finally(() => {
          loading.hide();
        });
    },
    // tpl (node, ctx, parent, index, props) {
    tpl(...args) {
      let { 0: node, 2: parent, 3: index } = args;
      let titleClass = node.selected
        ? "node-title node-selected"
        : "node-title";
      if (node.searched) titleClass += " node-searched";

      return (
        <span>
          <span
            class={titleClass}
            domPropsInnerHTML={node.title}
            onClick={() => this.$refs.tree.nodeSelected(node)}
          />
          <span class="commission_profile_btn">
            {this.get_commission_structure(node)} {node.commission_profile.name}
          </span>
        </span>
      );
    },
    get_commission_structure(node) {
      if (node.commission_structure && node.commission_structure.length) {
        let struct = node.commission_structure[0];
        let result = [];
        while (struct) {
          result.push(struct.percent_of_amount);
          struct = struct.children ? struct.children[0] : null;
        }
        return `${result.join("-")} ${node.commission_type} | `;
      }
    },

    openAddModal(parent) {
      this.parent = parent;
      this.$bvModal.show("tree-new-ib-modal");
    },
    search() {
      this.$refs.tree.searchNodes(this.searchword);
    },
    enterSearch(e) {
      if (e.key == "Enter") this.search();
    },
  },
  watch: {
    level_filter() {
      this.getPartnersTree();
    },
  },
  mounted() {
    this.getPartnersTree();
  },
};
</script>
<style>
.tree-list-tab .tree-node-el {
  padding-bottom: 10px !important;
}

.commission_profile_btn {
  background: #03b14f !important;
  color: white;
  padding: 6px 10px;
  border-radius: 20px;
}
.add-ib-btn {
  cursor: pointer;
  margin-left: 10px;
}
</style>
